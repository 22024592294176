import * as React from "react"
import Layout from "../components/layout/layout"

import 'bootstrap/dist/css/bootstrap.min.css';

import { useState } from "react";



import MicrosoftDP from '../components/Images/MicrosoftDP.png'
import WalmartDP from '../components/Images/WalmartDP.png'
import HubSpotDP from '../components/Images/HubSpotDP.png'
import AirBnbDP from '../components/Images/AirBnbDP.png'
import FedExDP from '../components/Images/FedExDP.png'
import GoogleDP from '../components/Images/GoogleDP.png'
import pricing from '../components/Images/Pricing.png'



import LandingPageHeadingAndTagline from '../components/LandingPageHeadingAndTagline/LandingPageHeadingAndTagline'
import PackagePrinter from "../components/PackagePrinter/PackagePrinter";
import CostumerContainer from "../components/CostumerContainer/CostumerContainer";
import ContactPanel from "../components/ContactPanel/ContactPanel";
import { Container } from "reactstrap";
import { Helmet } from "react-helmet"



const Pricing = () => {


  const [NameState, setNameState] = useState('')
  const [EmailState, setEmailState] = useState('')
  const [LinkedInIdState, setLinkedInIdState] = useState('')
  const [PhoneState, setPhoneState] = useState('')
  const [CompanyState, setCompanyState] = useState('')
  const [MessageState, setMessageState] = useState('')

  const [isLoading, setIsLoading] = useState(false)

  const nameRegex = /^[a-z ,.'-]+$/gi
  
  const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/g
   
  const phoneRegex = /^(?:(?:\+?1\s*(?:[.-]\s*)?)?(?:\(\s*([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9])\s*\)|([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9]))\s*(?:[.-]\s*)?)?([2-9]1[02-9]|[2-9][02-9]1|[2-9][02-9]{2})\s*(?:[.-]\s*)?([0-9]{4})(?:\s*(?:#|x\.?|ext\.?|extension)\s*(\d+))?$/g

  const companyRegex = /^(\(([^)]+)\))?[[:punct:]]?\p{Lu}+(?:[\s'-]?[\p{L}\d]+)+(\(([^)]+)\))*$/g

  const handleChange = (e, setState, validate, re) => {
    const newValue = e.target.value
    // if(validate(newValue, re)){
      setState(newValue)
    // }
    // else{
    //   alert("Invalid Characters")
    // }
  }

  const handleSubmit = async () => {
    try{
      setIsLoading(true)
      if(NameState==='' ||
      LinkedInIdState==='' ||
      EmailState==='' ||
      CompanyState==='' ||
      MessageState==='' ||
      PhoneState===''){
        alert("Please fill the asterik fields")
      }
      else{
        await fetch('https://dnnae-api-prod-bdhweedyzq-uc.a.run.app/public/contactForm/send',{
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            name: NameState,
            linkedInId: LinkedInIdState,
            workEmail: EmailState,
            companySize: CompanyState,
            message: MessageState,
            phone: PhoneState
          })

        })
          
        setIsLoading(false)
        alert("Message Sent")
      }
    }
    catch(e) {
      
      setIsLoading(false)
      console.log(e)
      
    }
  }

    return (
      <div>
        <Helmet title='DNNae | Pricing'/>


          <Layout 
              
              containerStyle={{
                position: 'relative'
              }}
              selectedState='pricing'
              pageTitle='Pricing'
          >
            <Container
            
                style={{
                  maxWidth: '1920px'
                }}
              >

                <LandingPageHeadingAndTagline
                    heading='Pricing Plans'
                    tagline='Try now, pay later.'
                    // conatinerStyle={{background: '#F7FAFF'}}
                    buttonName='Contact Sales'
                    headingColorStyle={{
                      color:'#0E3F89'
                    }}
                    pngSrc={pricing}
                    containerClassName='landingPageHeadingAndTagline_Container_Pricing'
                    pngSrcClassName='landingPageHeadingAndTagline_Image_Pricing'
                />
                <PackagePrinter
                
                    containerStyle={{
                        margin: '152px 13%'
                    }}
                
                />
  {/* 
          <CostumerContainer 
              containerStyle={{
                  margin: '0px 7%'
              }}
              mainLogos ={[
                  {pngSrc: AirBnbDP},
                  {pngSrc: HubSpotDP},
                  {pngSrc: GoogleDP},
                  {pngSrc: MicrosoftDP},
                  {pngSrc: WalmartDP},
                  {pngSrc: FedExDP}
              ]}
              
              /> */}

                <div style={{height:'94px', marginTop: '28px'}} id='contactSalesPanel'></div>
        <ContactPanel
                  // containerStyle={{
                  //   margin: '120px 7%'
                  // }}
                  heading='Contact us'
                  subTitle='Book a demo now'
                  statement1='Schedule a free personalized session today to learn more about DNNae and how we can help you crush your hiring goals.'
                  statement2= "<br/>Fill out this form or contact us at <a href='mailto:sales@dnnae.com'>sales@dnnae.com</a>"
                  handleSubmit={handleSubmit}
                  isLoading={isLoading}
                  formRequirements= {[
                    {
                      id: 1,
                      label:'Your Name',
                      type: 'text',
                      value: NameState,
                      setState: setNameState,
                      re: nameRegex,
                      handleChange
                    },{
                      id: 2,
                      label:'Your LinkedIn ID',
                      type: 'text',
                      value: LinkedInIdState,
                      setState: setLinkedInIdState,
                      handleChange
                    },{
                      id: 3,
                      label:'Work Email',
                      type: 'email',
                      value: EmailState,
                      setState: setEmailState,
                      re: emailRegex,
                      handleChange
                    },{
                      id: 4,
                      label:'Phone',
                      type: 'text',
                      value: PhoneState,
                      setState: setPhoneState,
                      re: phoneRegex,
                      handleChange
                    },{ 
                      id: 5,
                      label:'Company Size',
                      type: 'text',
                      value: CompanyState,
                      setState: setCompanyState,
                      re: companyRegex,
                      handleChange
                    },{
                      id: 6,
                      label:'Message',
                      type: 'textarea',
                      value: MessageState,
                      setState: setMessageState,
                      handleChange
                      }
                    
                  ]}
                />


            </Container>
          </Layout>        
      </div>
    )
}

export default Pricing
