import React from 'react'
import { Button } from 'reactstrap'


import './PackageDetails.scss'
import '../GlobalStyle.scss'

import PackagePricing from './PackagePricing'


const PackageDetails = ({
    
    packageStyle={},
    priceStyle={},
    packageType,
    packageLable,
    commonPackage,
    monthlyPrice,
    goldenPackage,
    yearlyPrice,
    btn

}) => {

    return (
        <div 
            style={{...packageStyle}}
            className='packageDetails'
        >
            
            <div>
                <h2 className='globalHeadingStyle packageDetailsHeadingSpacing'>
                    {packageType}
                </h2>
                <p className='globalParagraphStyle packageDetailsParagraphSpacing'>
                    {packageLable}
                </p>
            </div>
            <PackagePricing
                monthlyPrice={monthlyPrice}
                yearlyPrice={yearlyPrice}
                priceStyle={priceStyle}
            />
            <div className='globalParagraphStyle' style={{marginTop:'28px'}}>
                {commonPackage.map((item, i) => <p className='packageItemSpacing' key={i}>{item}</p>)}
                {goldenPackage.map((item, i) => <p className='packageItemSpacing' className={packageType === 'Principal' ? 'disabled' : ''} key={i}>{item}</p>)}
            </div>
            <PackagePricing
                monthlyPrice={monthlyPrice}
                yearlyPrice={yearlyPrice}
                priceStyle={priceStyle}
            />
            <a href='#contactSalesPanel' className='pricingButtonControl'>
                <Button 
                    className='buttonProp'
                    color='primary' 
                    style={{
                        bottom:'28px'
                    }}
                >
                    {btn}
                </Button>
            </a>
           
        </div>
    )
}


export default PackageDetails
