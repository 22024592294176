import React from 'react'


import './PackagePricing.scss'
import '../GlobalStyle.scss'



const PackagePricing = ({

    monthlyPrice,
    yearlyPrice,
    priceStyle

}) => {

    return (
        <div className='packagePricing'>
            <h1
                style={{...priceStyle}}
            >
                {`$${monthlyPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}/mo`}
            </h1>
            <p>
                {`Billed annually - $${yearlyPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}/yr`}
            </p>
        </div>
    )
}

export default PackagePricing
