import React from 'react'
import PackageDetails from '../PackageDetails/PackageDetails'

import './PackagePrinter.css'



const PackagePrinter = ({
    containerStyle={}
}) => {
    return (
        <div 
        
            style={{...containerStyle}}
            className='packagePrinter'
        
        >
            <PackageDetails 
                packageStyle={{
                    background: '#F8F9FA'
                }}
                packageType= 'Principal'
                packageLable='Recommended for solo users'

                commonPackage={[ 'Score Unlimited Prospects',
                'Export Unlimited CSVs',
                'Automated Meeting Setup',
                'Unlimited LinkedIn and Email Messaging',
                'Demographics AI: Gender, Age, Ethnicity',
                'Best Fit Profile AI','Experience Match AI',
                'Skill Match AI',
                'Built-in ATS with Activity Tracking']
                }

                goldenPackage={['Scheduling AI Assistant','Tribes: Source and Message with your team']}

                monthlyPrice= '489'
                yearlyPrice= '5868'

                btn='Contact Sales'
                priceStyle={{color: '#2e2e2e'}}
               
                />
            <PackageDetails 
                packageStyle={{
                    background: '#F7FAFF'
                }}
                packageType= 'MD'
                packageLable='Unlock the power of your Tribe'

                commonPackage={[ 'Score Unlimited Prospects',
                'Export Unlimited CSVs',
                'Automated Meeting Setup',
                'Unlimited LinkedIn and Email Messaging',
                'Demographics AI: Gender, Age, Ethnicity',
                'Best Fit Profile AI','Experience Match AI',
                'Skill Match AI',
                'Built-in ATS with Activity Tracking',
                'Scheduling AI Assistant',
                'Tribes: Source and Message with your team']
                }

                goldenPackage={[]}

                monthlyPrice= '889'
                yearlyPrice= '10668'

                btn='Contact Sales'

                />                
        </div>
    )
}

export default PackagePrinter
